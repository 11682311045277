import React from "react";
import "./LanguageFlagButton.css";
import { useTranslation,  } from "react-i18next";

function LanguageFlagButton(props: { language: string; }) {
  const { language } = props;

  const { i18n } = useTranslation();
  const flagTranslation = i18n.getFixedT(language);

  const handleLangChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
      <button
        className="language_flag_button"
        onClick={() => handleLangChange(language)}
      >
        {flagTranslation('config.language_flag')}
      </button>
  );
}

export default LanguageFlagButton;
