import { t } from "i18next";
import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify"
import "./QuestForm.css";

interface IProps {
    closePrivacyPolicy: () => void;
}

function PrivacyPolicyPopup({closePrivacyPolicy}: IProps) {

  return (
    <div className="quest-form__container">
        <div className="quest-form__privacy_policy">
          <div className="quest-form__privacy_policy_content">
            <span
              className="quest-form__notification_close"
              onClick={() => closePrivacyPolicy()}
            >
              &times;
            </span>
            <h2>{t("privacy_policy.title")}</h2>
            <h3>{t("privacy_policy.data_controller_title")}</h3>
            <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(t("privacy_policy.data_controller_label"))}}></p>
            <h3>{t("privacy_policy.register_name_title")}</h3>
            <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(t("privacy_policy.register_name_label"))}}></p>
            <h3>{t("privacy_policy.legal_basis_title")}</h3>
            <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(t("privacy_policy.legal_basis_label"))}}></p>
            <h3>{t("privacy_policy.personal_data_title")}</h3>
            <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(t("privacy_policy.personal_data_label"))}}></p>
            <h3>{t("privacy_policy.retention_period_title")}</h3>
            <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(t("privacy_policy.retention_period_label"))}}></p>
            <h3>{t("privacy_policy.recipient_data_title")}</h3>
            <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(t("privacy_policy.recipient_data_label"))}}></p>
            <h3>{t("privacy_policy.rights_data_title")}</h3>
            <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(t("privacy_policy.rights_data_label"))}}></p>
            <h3>{t("privacy_policy.data_protection_title")}</h3>
            <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(t("privacy_policy.data_protection_label"))}}></p>
            <h3>{t("privacy_policy.changes_title")}</h3>
            <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(t("privacy_policy.changes_label"))}}></p>
            <button
              className="quest-form__notification_button"
              type="button"
              onClick={() => closePrivacyPolicy()}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
  );
}

export default PrivacyPolicyPopup;
