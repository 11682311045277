import React from "react";
import QuestForm from "./Components/QuestForm";
import LanguageFlagButton from "./Components/LanguageFlagButton";
import "./App.css";
import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation();

  return (
    <div className="app__container">
      <div className="app__flag_container">
        <LanguageFlagButton language="fi" />
        <LanguageFlagButton language="en" />
      </div>
      <img className="app_punos_logo" src="/punos-logo.png" alt="Punos Mobile" />
      <h1 className="app__header">{t("app.header")}</h1>
      <QuestForm />
    </div>
  );
}

export default App;
