import { t } from "i18next";
import React, { useState, useEffect } from "react";
import { format, parseISO, addHours, setHours, setMinutes } from "date-fns";
import {fi} from "date-fns/locale"
import "./QuestForm.css";
import PrivacyPolicyPopup from "./PrivacyPolicyPopup";

interface GeneralError {
  code: number;
  message: string;
}

function QuestForm() {
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [recipient, setRecipient] = useState("");
  const [company, setCompany] = useState("");
  const [isEdited, setIsEdited] = useState(false);
  const [office, setOffice] = useState("");
  const [consent, setConsent] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<GeneralError>()

  useEffect(() => {
    getCurrentLocation();
    setDate(getCurrentDate());
    setStartTime(getCurrentTime());

    const dateTimeInterval = setInterval(() => {
      if (!isEdited) {
        setDate(getCurrentDate());
        setStartTime(getCurrentTime());
      } else {
        clearInterval(dateTimeInterval);
      }
    }, 5000);

    return () => clearInterval(dateTimeInterval);
  }, [isEdited]);

  const getCurrentTime = () => 
    getTime(new Date());

  const getCurrentDate = () => format(new Date(), "yyyy-MM-dd");

  const getCurrentLocation = async () => {
    const { state } = await navigator.permissions.query({
      name: "geolocation",
    });
    if (state === "granted") {
      const coords = await getPosition();
      setClosestOffice(coords);
    }
    if (state === "prompt") {
      const coords = await getPosition();
      setClosestOffice(coords);
    }
    if (state === "denied") {
      console.log("Geolocation permission status: denied");
    }
  };

  const getPosition = (): Promise<GeolocationCoordinates> => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position.coords);
        },
        (err) => {
          reject(err);
        },
        { enableHighAccuracy: true }
      );
    });
  };

  const setClosestOffice = (currentCoords: GeolocationCoordinates) => {
    const kouvolaCoords = { lat: 60.9322774, lon: 26.1721886 };
    const helsinkiCoords = { lat: 60.1097542, lon: 24.6890608 };

    const distanceToKouvola = calculateDistance(currentCoords, kouvolaCoords);
    const distanceToHelsinki = calculateDistance(currentCoords, helsinkiCoords);

    distanceToHelsinki < distanceToKouvola
      ? setOffice("helsinki")
      : setOffice("kouvola");
  };

  const calculateDistance = (
    currentCoords: GeolocationCoordinates,
    cityCoords: { lat: number; lon: number }
  ) => {
    const R = 6371e3;
    const x =
      (cityCoords.lon - currentCoords.longitude) *
      Math.cos((currentCoords.latitude + cityCoords.lat) / 2);
    const y = cityCoords.lat - currentCoords.latitude;
    return Math.sqrt(x * x + y * y) * R;
  };

  const handleSignIn = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (!consent) return;

    setIsLoading(true);

    const items = event.target as typeof event.target & {
      name: { value: string };
      startDate: { value: string };
      endDate: { value: string };
      date: { value: string };
      company: { value: string };
      recipient: { value: string };
      consent: { value: boolean };
    };

    const startDateTime = joinDateAndTime(
      items.date.value,
      items.startDate.value
    );
    const endDateTime = joinDateAndTime(items.date.value, items.endDate.value);

    const visitorObject = {
      name: items.name.value,
      startDate: startDateTime,
      endTime: endDateTime,
      recipient: items.recipient.value,
      company: company,
      office: office,
      consent: consent,
    };

    const API_URL =
      process.env.NODE_ENV === "production"
        ? "/api/visitor/"
        : "http://localhost:8000/api/visitor/";
    try {
      const response = await fetch(API_URL, {
        method: "POST",
        body: JSON.stringify({
          name: visitorObject.name,
          startdate: visitorObject.startDate,
          enddate: visitorObject.endTime,
          recipient: visitorObject.recipient,
          office: visitorObject.office,
          company: visitorObject.company,
          consent: visitorObject.consent,
        }),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      });
  
      if (!response.ok) {
        console.log("ERROR " + JSON.stringify(response.body));
        setError({code: response.status, message: t("quest_form.error_adding_visitor")})
        setIsLoading(false);
      }

      if (response.body !== null) {
        setName("");
        setStartTime("");
        setEndTime("");
        setDate("");
        setRecipient("");
        setOffice("");
        setCompany("");
        setIsEdited(false);
        setConsent(false);
        setShowNotification(true);
        setIsLoading(false);
  
        setTimeout(() => {
          setShowNotification(false);
        }, 10000);
      }
    } catch (e) {
      console.error('Error', e);
      setError({code: 500, message: t('quest_form.error_unexpected')});
      setIsLoading(false);
      setShowNotification(true);
    }
    
  };

  const formChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsEdited(true);

    switch (event.target.name) {
      case "name":
        setName(event.target.value);
        break;

      case "startDate":
        setStartTime(event.target.value);
        break;

      case "endDate":
        setEndTime(event.target.value);
        break;

      case "date":
        setDate(event.target.value);
        break;

      case "recipient":
        setRecipient(event.target.value);
        break;

      case "company":
        setCompany(event.target.value);
        break;

      case "office":
        setOffice(event.target.value);
        break;

      case "consent":
        setConsent(event.target.checked);
        break;

      default:
        break;
    }
  };

  const timeChangeHandler = (hours: number) => {
    const startDate = new Date();
    const endDate = addHours(startDate, hours);

    setIsEdited(true);
    setStartTime(getTime(startDate));
    setEndTime(getTime(endDate));
    setDate(format(startDate, "yyyy-MM-dd"));
  };

  const getTime = (date: Date): string => {
    return format(date, "HH:mm", {locale: fi});
  };

  const joinDateAndTime = (date: string, time: string): Date => {
    const baseDate = parseISO(date);
    const timeArray = time.split(":").map((x) => Number(x));

    let newDate = baseDate;
    newDate = setHours(newDate, timeArray[0]);
    newDate = setMinutes(newDate, timeArray[1]);

    return newDate;
  };

  const closeNotification = () => {
    setShowNotification(false);
  };

  const openPrivacyPolicy = () => {
    setShowPrivacyPolicy(true);
  };

  const closePrivacyPolicy = () => {
    setShowPrivacyPolicy(false);
  };

  return (
    <div className="quest-form__container">
      {showNotification && (
        <div className="quest-form__notification">
          <div className="quest-form__notification_content">
            <span
              className="quest-form__notification_close"
              onClick={() => closeNotification()}
            >
              &times;
            </span>
            <h1>{!!error ? t("quest_form.visitor_error") : t("quest_form.visitor_thanks")}</h1>
            <p>{!!error ? error.message : t("quest_form.visitor_information_saved")}</p>
            <button
              className="quest-form__notification_button"
              type="button"
              onClick={() => closeNotification()}
            >
              Ok
            </button>
          </div>
        </div>
      )}
      {showPrivacyPolicy && (
        <PrivacyPolicyPopup closePrivacyPolicy={closePrivacyPolicy} />
      )}
      <form className="quest-form__main" onSubmit={handleSignIn}>
        <label>{t("quest_form.visitor_label")}</label>
        <input
          type="text"
          name="name"
          value={name}
          onChange={formChangeHandler}
          placeholder={t("quest_form.visitor_placeholder")}
          required
        />

        <div className="quest-form___time-container">
          <div className="quest-form___time-main">
            <label>{t("quest_form.time_label")}</label>
            <input
              type="date"
              name="date"
              value={date}
              onChange={formChangeHandler}
              placeholder={t("quest_form.time_placeholder")}
              required
            />
          </div>
          <div className="quest-form___time-main">
            <label>{t("quest_form.time_label")}</label>
            <input
              type="time"
              name="startDate"
              value={startTime}
              onChange={formChangeHandler}
              placeholder={t("quest_form.time_placeholder")}
              required
            />
          </div>

          <div className="quest-form___time-main">
            <label>{t("quest_form.end_time_label")}</label>
            <input
              type="time"
              name="endDate"
              value={endTime}
              onChange={formChangeHandler}
              placeholder={t("quest_form.time_placeholder")}
              required
            />
          </div>

          <div className="quest-form___time-button-container">
            {[...Array(4)].map((e, i) => (
              <button
                key={i}
                onClick={() => timeChangeHandler(i + 1)}
                type="button"
              >
                {i + 1}h
              </button>
            ))}
          </div>
        </div>

        <label>{t("quest_form.recipient_label")}</label>
        <input
          type="text"
          name="recipient"
          value={recipient}
          onChange={formChangeHandler}
          placeholder={t("quest_form.recipient_placeholder")}
          required
        />
        <label>{t("quest_form.visitor_company_label")}</label>
        <input
          type="text"
          name="company"
          value={company}
          onChange={formChangeHandler}
          placeholder={t("quest_form.visitor_company_placeholder")}
          required
        />
        <label>{t("quest_form.office_label")}</label>
        <div className="quest-form___office-container">
          <input
            id="helsinki"
            type="checkbox"
            name="office"
            value="helsinki"
            className="quest-form___checkbox"
            checked={office === "helsinki"}
            onChange={formChangeHandler}
          />
          <label htmlFor="helsinki" className="quest-form___checkbox_label">
            Helsinki
          </label>

          <input
            id="kouvola"
            type="checkbox"
            name="office"
            value="kouvola"
            className="quest-form___checkbox"
            checked={office === "kouvola"}
            onChange={formChangeHandler}
          />
          <label htmlFor="kouvola" className="quest-form___checkbox_label">
            Kouvola
          </label>
        </div>
        <div className="quest-form___consent-container">
          <input
            id="consent"
            type="checkbox"
            name="consent"
            value="consent"
            className="quest-form___checkbox"
            checked={consent}
            onChange={formChangeHandler}
            required
          />
          <label htmlFor="consent" className="quest-form___checkbox_label">
            {t("quest_form.visitor_consent_label")}
            <a
              href="#!"
              className="quest-form___checkbox_link"
              onClick={openPrivacyPolicy}
            >
              {t("quest_form.visitor_consent_link")}
            </a>
          </label>
        </div>

        <button type="submit">
          {isLoading ? (
            <span className="loader"></span>
          ) : (
            t("quest_form.submit")
          )}
        </button>
      </form>
    </div>
  );
}

export default QuestForm;
